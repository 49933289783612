import { SearchInputStyle } from '@styles/common/input/InputStyles';
import React, { useState } from 'react';

const SearchInput = ({
  placeholder,
  width,
  searchEvent = null,
  searchRegister = null,
  disabled = false,
  defaultValue = null,
}) => {
  const [value, setValue] = useState(null);
  const { onChange: hookFormOnChange, ...hookFormRegister } = searchRegister
    ? searchRegister
    : { onChange: null };

  return (
    <SearchInputStyle width={width}>
      <input
        defaultValue={defaultValue ? defaultValue : ''}
        type="search"
        placeholder={placeholder}
        onChange={(e) => {
          setValue(e.target.value);
          hookFormOnChange && hookFormOnChange(e);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') searchEvent && searchEvent(value);
        }}
        disabled={disabled}
        {...hookFormRegister}
      />
    </SearchInputStyle>
  );
};

export default SearchInput;
