import { authAPI } from '../core/instance';
import {
  GetAssignmentListResponse,
  GetMyContentsClassifications,
  GetStudyContentListResponse,
} from './dashboard.interface';
import { getUrlByQueryParameterObj } from '@asset/function/queryStringFunctions';
import _ from 'lodash';
import {
  StudyContentListOrderBy,
  StudyContentType,
} from '@asset/enum/materialEnum';
import { MyContentType } from '@asset/enum/studyContentEnum';

export const getStudentPageList = async (queryStringObj) => {
  let url = `/api/students/list/`;
  if (queryStringObj) {
    const queryString = getUrlByQueryParameterObj('', queryStringObj);
    url += queryString;
  }

  const data = await authAPI.get(url).then((response) => {
    return response.data;
  });
  return data;
};
export const getTeacherInfoData = async (teacherId) => {
  const data = await authAPI
    .get(`/api/teachers/teacher-info/${teacherId}/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const getTeacherPageList = async (queryStringObj) => {
  let url = `/api/teachers/teacher-info/`;
  if (queryStringObj) {
    const queryString = getUrlByQueryParameterObj('', queryStringObj);
    url += queryString;
  }

  const data = await authAPI.get(url).then((response) => {
    return response.data;
  });
  return data;
};
export const getRecentStudentStudyStatusList = async (queryObj) => {
  const queryString = getUrlByQueryParameterObj('', queryObj);
  const url = `/api/teachers/student-study-status/recent/${queryString}`;
  const data = await authAPI.get(url).then((response) => {
    return response.data;
  });
  return data;
};
export const getTotalStudentStudyStatusList = async (queryObj) => {
  const queryString = getUrlByQueryParameterObj('', queryObj);
  const url = `/api/teachers/student-study-status/total/${queryString}`;
  const data = await authAPI.get(url).then((response) => {
    return response.data;
  });
  return data;
};
export const getStudentUserList = async () => {
  const studentList = await authAPI
    .get(`/api/students/list/?pageType=side-bar`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return studentList;
};
export const getAssignmentList = async (
  studentId,
  pageNumber = 1,
  pageSize = 15,
  showHidden = false,
  startDateTime = null,
  endDateTime = null,
) => {
  let apiUrl = `/api/students/${studentId}/my-assignments/?page=${pageNumber}&pageSize=${pageSize}&showHidden=${showHidden}`;
  if (startDateTime && endDateTime) {
    apiUrl += `&startDateTime=${encodeURIComponent(
      startDateTime,
    )}&endDateTime=${encodeURIComponent(endDateTime)}`;
  }
  const data = await authAPI
    .get<GetAssignmentListResponse>(apiUrl)
    .then((response) => {
      return response.data;
    });
  return data;
};

export const getStudyContentList = async (
  studentId: number,
  studyContentType:
    | StudyContentType.MY_STUDY_MATERIAL
    | StudyContentType.MY_TEST,
  pageNumber = 1,
  pageSize = 15,
  isHidden: boolean | null = null,
  studyMaterialCourse: string | null = null,
  hasAssignment: boolean | null = null,
  search: string | null = null,
  isBookmarked: boolean | null = null,
  orderBy: StudyContentListOrderBy[] | StudyContentListOrderBy = null,
) => {
  let apiUrl = `/api/study-data/my-contents/?studentId=${studentId}&studyType=${studyContentType}&page=${pageNumber}&pageSize=${pageSize}`;
  if (!_.isNull(isHidden)) apiUrl += `&isHidden=${isHidden}`;
  if (studyMaterialCourse)
    apiUrl += `&studyMaterialCourse=${studyMaterialCourse}`;
  if (!_.isNull(hasAssignment)) apiUrl += `&hasAssignment=${hasAssignment}`;
  if (search) apiUrl += `&search=${search}`;
  if (!_.isNull(isBookmarked)) apiUrl += `&isBookmarked=${isBookmarked}`;
  if (!_.isNull(orderBy)) apiUrl += `&orderBy=${orderBy}`;

  const studyContentList = await authAPI
    .get<GetStudyContentListResponse>(apiUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return studyContentList;
};
export const getStudyMaterialStatusList = async (
  studentId: number,
  page = 1,
  pageSize = 15,
) => {
  return await authAPI
    .get(
      `/api/study-data/study-material-status/list/?studentId=${studentId}&page=${page}&pageSize=${pageSize}`,
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getTestGraphDataList = async (studentId) => {
  const data = await authAPI
    .get(`/api/students/${studentId}/my-test-graph/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const getStudyHistoryData = async (studentId) => {
  const data = await authAPI
    .get(`/api/teachers/study-history/?studentId=${studentId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export async function getStatisticsData(
  startDate: number,
  endDate: number,
  studentId: number,
  timezoneOffset: number,
) {
  const data = await authAPI
    .get(
      `/api/students/${studentId}/my-study-records/?startDate=${startDate}&endDate=${endDate}&timezoneOffset=${timezoneOffset}`,
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
}

export const getSchoolNameList = async () => {
  const data = await authAPI.get(`/api/students/schools/`).then((response) => {
    return response.data;
  });
  return data;
};
export const getMaterialName = async (subject, course, materialType) => {
  let url = `/api/materials/autocomplete/`;
  if (subject || course || materialType) {
    const queryString = getUrlByQueryParameterObj('', {
      subject,
      course,
      materialType,
    });
    url += queryString;
  }
  const data = await authAPI
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const getCourseList = async (subject) => {
  const data = await authAPI
    .get(`/api/materials/autocomplete/?subject=${subject}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const getStudentCode = async (studentId) => {
  const data = await authAPI
    .get(`/api/teachers/student-code/${studentId}/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const getStudentInfo = async (studentId) => {
  const data = await authAPI
    .get(`/api/teachers/student-info/${studentId}/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const updateStudentInfoGeoApi = async (studentId, reqBody) => {
  const data = await authAPI
    .patch(`/api/teachers/student-geo-language-info/${studentId}/`, reqBody)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const updateStudentStatus = async (studentId, status) => {
  const data = await authAPI
    .patch(`/api/teachers/student-status/${studentId}/`, { status })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const postMyStudyContent = async (
  studentId,
  reqData,
): Promise<{
  success: boolean;
  newObject: {
    id: number;
    materialName: string;
    imageUrl: string;
    studyType: StudyContentType;
    myContentType: MyContentType;
    totalProblemCount: number;
    progressRate: number;
    correctRate: number;
    startPage: number;
    lastPage: number;
  };
  message?: string;
}> => {
  const data = await authAPI
    .post(`/api/students/${studentId}/my-study-materials/`, reqData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const postStudyHistory = async (studentId, reqData) => {
  const data = await authAPI
    .post(`/api/teachers/study-history/?studentId=${studentId}`, reqData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const patchStudentInfo = async (studentId, reqData) => {
  const data = await authAPI
    .patch(`/api/teachers/student-info/${studentId}/`, reqData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const postSchoolExam = async (studentId, reqData) => {
  const data = await authAPI
    .post(`/api/students/${studentId}/my-school-exams/`, reqData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const postMockTest = async (studentId, reqData) => {
  const data = await authAPI
    .post(`/api/students/${studentId}/my-mock-tests/`, reqData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const postStudentInfo = async () => {
  const data = await authAPI
    .post(`/api/teachers/student-info/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const getStudentListApi = async () => {
  const data = await authAPI
    .get(`/api/teachers/student-info/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const postTeacherInfo = async (reqData) => {
  const data = await authAPI
    .post(`/api/teachers/teacher-info/`, reqData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const patchTeacherInfo = async (teacherId, reqData) => {
  const data = await authAPI
    .patch(`/api/teachers/teacher-info/${teacherId}/`, reqData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const getStudyMaterialRecord = async (
  studyContentId,
  startDate,
  endDate,
  classificationId,
  classificationType,
  timezoneOffset,
) => {
  const data = await authAPI
    .get(
      `/api/students/study-report/toggle/${studyContentId}/?startDate=${startDate}&endDate=${endDate}&timezoneOffset=${timezoneOffset}${
        classificationId
          ? '&currentClassificationId=' +
            classificationId +
            '&currentClassificationType=' +
            classificationType
          : ''
      }`,
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const getStudyReportData = async (studyReportId) => {
  const data = await authAPI
    .get(`/api/students/study-report/${studyReportId}/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const getFilteringListData = async () => {
  const data = await authAPI
    .get(`/api/teachers/student-study-status/filter/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getMoteMotePlanListData = async (
  studentId,
  timezoneOffset,
  startDate = null,
  endDate = null,
) => {
  let api = `/api/students/motemote/?studentId=${studentId}&timezoneOffset=${timezoneOffset}`;
  if (startDate) {
    api += `&startDate=${startDate}&endDate=${endDate}`;
  }
  const data = await authAPI
    .get(api)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const patchNewPassword = async (reqData) => {
  const data = await authAPI
    .patch(`/api/users/password/`, reqData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const getAssignmentTempProblemCount = async (reqData) => {
  const data = await authAPI
    .post(`/api/students/my-assignmnets/options/`, reqData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const changeErrorReportIsResolved = async (isResolved, reportId) => {
  const data = await authAPI
    .get(
      `/api/study-data/error-report/?isResolved=${isResolved}&reportId=${reportId}`,
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const checkStudyStatusApi = async (reqBody, course, studentId) => {
  const data = await authAPI
    .patch(
      `/api/study-data/learning-status/detail/?course=${course}&studentId=${studentId}`,
      reqBody,
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const updateStudentEmail = async (reqBody) => {
  const data = authAPI
    .post(`/api/teachers/student/change-email/save/`, reqBody)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const validateStudentEmail = async (reqBody) => {
  const data = authAPI
    .post(`/api/teachers/student/change-email/validation/`, reqBody)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getDailyReportApi = async (studentId, startOfDay) => {
  const data = await authAPI
    .get(
      `/api/study-data/daily-report/?studentId=${studentId}&startOfDay=${startOfDay}`,
    )
    .then((response) => {
      return response.data;
    });
  return data;
};
export const getMyContentsClassifications = async (
  studentId: number,
  studyContentType:
    | StudyContentType.MY_STUDY_MATERIAL
    | StudyContentType.MY_TEST,
  classificationType: 'studyMaterialCourse' | 'subject',
  isHidden: boolean | null = null,
  hasAssignment: boolean | null = null,
  search: string | null = null,
) => {
  let apiUrl = `/api/study-data/my-contents-classifications/?studentId=${studentId}&studyType=${studyContentType}&classificationType=${classificationType}`;
  if (!_.isNull(isHidden)) apiUrl += `&isHidden=${isHidden}`;
  if (!_.isNull(hasAssignment)) apiUrl += `&hasAssignment=${hasAssignment}`;
  if (search) apiUrl += `&search=${search}`;
  const data = await authAPI
    .get<GetMyContentsClassifications>(apiUrl)
    .then((response) => {
      return response.data;
    });
  return data;
};
export const putMyStudyContentBookmark = async (
  myContentId: number,
  requestData: {
    isBookmarked: boolean;
  },
) => {
  const data = await authAPI
    .put(`/api/study-data/my-contents/${myContentId}/bookmark/`, requestData)
    .then((response) => {
      return response.data;
    });
  return data;
};
