import React, { useEffect, useRef, useState } from 'react';
import { SelectStudentDropdownStyle } from '@styles/common/dropDown/dropDownStyles';
import { selectedStudentIdAtom } from '@atoms/states';
import { useRouter } from 'next/router';
import * as amplitude from '@amplitude/analytics-browser';
import { useEventPropertiesStore } from '@store/common';
import Select, { components } from 'react-select';
import { changeKeyName } from '@asset/function/commonFunctions';
import Image from 'next/image';
import { studentInfoFormSelectCustomStyle } from '@styles/common/formStyles';
import Link from 'next/link';
import { useSelectedStudentInfoQuery } from '@apis/query/user';
import { useRecoilState } from 'recoil';
import { StudyContentType } from '@asset/enum/materialEnum';

export default function SelectStudentDropdown({ studentList }) {
  const router = useRouter();
  const { properties } = useEventPropertiesStore();
  const openDropDownRef = useRef(null);
  const dropDownInputRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [pageType, setPageType] = useState('main');
  const [, setSelectedStudentId] = useRecoilState(selectedStudentIdAtom);
  const [optionList, setOptionList] = useState([]);

  const { data: selectedStudent } = useSelectedStudentInfoQuery();

  const CustomControl = ({ children, ...props }: any) => {
    return (
      <div className="p-2">
        <components.Control {...props}>
          <Image src={'/common/icon/search.svg'} width={24} height={24}></Image>
          {children}
        </components.Control>
      </div>
    );
  };
  const CustomOption = ({ children, ...props }: any) => {
    return (
      <components.Option
        {...props}
        isSelected={selectedStudent?.id === props.data.value}
      >
        {children}
      </components.Option>
    );
  };

  const focusOutItemListHandler = (event) => {
    if (
      dropDownInputRef.current &&
      openDropDownRef.current &&
      !openDropDownRef.current.contains(event.target) &&
      !dropDownInputRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', focusOutItemListHandler);
  }, []);

  useEffect(() => {
    if (router.isReady) {
      setPageType(
        router.asPath.startsWith('/manage/dashboard/')
          ? router.query.type
            ? router.query?.type.length == 1
              ? 'main'
              : router.query?.type[0]
            : 'main'
          : 'main',
      );
    }
  }, [router]);

  useEffect(() => {
    setOptionList([
      {
        label: '나의 학생',
        options: changeKeyName(
          studentList.myStudentList,
          ['id', 'name'],
          ['value', 'label'],
        ),
      },
      {
        label: '다른 학생',
        options: changeKeyName(
          studentList.otherStudentList,
          ['id', 'name'],
          ['value', 'label'],
        ),
      },
    ]);
  }, [studentList]);

  return (
    <>
      <div className="flex items-center gap-2 text-label-lg-500 text-my-gray-100">
        {selectedStudent ? (
          <Link href={`/manage/dashboard/main/${selectedStudent.id}`}>
            <a className="main-student-name">{selectedStudent.name}</a>
          </Link>
        ) : (
          <span className="main-student-name">학생 이름</span>
        )}

        <button
          aria-label="toggle menu"
          type="button"
          ref={openDropDownRef}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <img src="/common/icon/gnb/list_arrow_icon.svg"></img>
        </button>
      </div>
      <SelectStudentDropdownStyle>
        <div ref={dropDownInputRef}>
          {isOpen ? (
            <Select
              options={optionList}
              noOptionsMessage={() => '검색결과 없음'}
              menuIsOpen={true}
              isSearchable={true}
              backspaceRemovesValue={true}
              components={{
                Control: CustomControl,
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                Option: CustomOption,
              }}
              closeMenuOnSelect={false}
              styles={studentInfoFormSelectCustomStyle}
              placeholder={'학생 검색'}
              onChange={async (selectedValue: any) => {
                setSelectedStudentId(selectedValue.value);
                router.push(
                  pageType === StudyContentType.MY_STUDY_MATERIAL ||
                    pageType === StudyContentType.MY_TEST
                    ? `/manage/dashboard/${pageType}/${selectedValue.value}?isHidden=false`
                    : `/manage/dashboard/${pageType}/${selectedValue.value}`,
                );
                setIsOpen(!isOpen);
                amplitude.track(`click_main_nav_student_list_drop_down`, {
                  ...properties,
                  studentId: selectedValue?.value,
                });
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </SelectStudentDropdownStyle>
    </>
  );
}
