import { authAPI } from '../core/instance';

export const usersGoogleLoginCallback = async (code) => {
  const response = await authAPI
    .post('/api/users/google/login/callback/', {
      code,
    })
    .then((response) => {
      const { access_token } = response.data;
      authAPI.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${access_token}`;
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return response;
};

export const usersLogOut = async () => {
  const response = await authAPI
    .post('/api/users/token/blacklist/')
    .then(() => {
      authAPI.defaults.headers.common['Authorization'] = '';
    })
    .catch((error) => {
      return error.response.data;
    });
  return response;
};

export const userLogin = async (requestData) => {
  const data = await authAPI
    .post(`/api/users/login/`, requestData)
    .then((response) => {
      if (response.data.success) {
        const { access_token } = response.data;
        authAPI.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${access_token}`;
      }
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const userSignup = async (requestData) => {
  const data = await authAPI
    .post(`/api/users/signup/`, requestData)
    .then((response) => {
      if (response.data.success) {
        window.location.replace(
          `/account/signup/email-authentication/?email=${encodeURIComponent(
            requestData.email,
          )}`,
        );
      }
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const userCode = async (requestData) => {
  const data = await authAPI
    .patch(`/api/users/code/`, requestData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const sendEmailAuthentication = async () => {
  const data = await authAPI
    .get(`/api/users/auth/email/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const checkEmailAuthentication = async () => {
  const data = await authAPI
    .get(`/api/users/auth/email/check/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const userEmailAuthenticationCheck = async (requestData) => {
  const data = await authAPI
    .patch(`/api/users/auth/email/complete/`, requestData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const resetPasswordEmailCheck = async (requestData) => {
  const data = await authAPI
    .patch(`/api/users/auth/reset-password/email/`, requestData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const resetPassword = async (requestData) => {
  const data = await authAPI
    .patch(`/api/users/password/reset/`, requestData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
