import React from 'react';
import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

interface props extends TooltipProps {
  customStyle?: {
    padding?: string;
    fontSize?: string;
    fontWeight?: number;
    marginTop?: string;
    borderRadius?: string;
  };
}

const BasicArrowTooltip = ({ customStyle, ...props }: props) => {
  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      borderRadius:
        customStyle && customStyle.borderRadius
          ? customStyle.borderRadius
          : '0.25rem',
      padding:
        customStyle && customStyle.padding
          ? customStyle.padding
          : '0.5rem 1rem',
      marginTop:
        customStyle && customStyle.marginTop
          ? `${customStyle.marginTop} !important`
          : '14px',
      backgroundColor: 'var(--gray-20)',
      color: 'var(--gray-100)',
      fontSize:
        customStyle && customStyle.fontSize ? customStyle.fontSize : '0.75rem',
      fontWeight:
        customStyle && customStyle.fontWeight ? customStyle.fontWeight : 500,
      userSelect: 'none',
    },
    [`& .${tooltipClasses.tooltip} > span::before`]: {
      backgroundColor: 'var(--gray-20)',
    },
  }));

  return <CustomTooltip {...props}></CustomTooltip>;
};

export default BasicArrowTooltip;
