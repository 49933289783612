import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
dayjs.extend(isoWeek);

export const ISO_WEEKDAY = ['월', '화', '수', '목', '금', '토', '일'];
export const ISO_WEEKDAY_ENG = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
];
export const WEEKDAY_ENG = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];
export const WEEKDAY = ['일', '월', '화', '수', '목', '금', '토'];
export const TRANSLATE_WEEKDAY_1_WORD_LIST = [
  'common:unit.weekDay1.Sunday',
  'common:unit.weekDay1.Monday',
  'common:unit.weekDay1.Tuesday',
  'common:unit.weekDay1.Wednesday',
  'common:unit.weekDay1.Thursday',
  'common:unit.weekDay1.Friday',
  'common:unit.weekDay1.Saturday',
];
export const TRANSLATE_WEEKDAY_2_WORD_LIST = [
  'common:unit.weekDay2.Sunday',
  'common:unit.weekDay2.Monday',
  'common:unit.weekDay2.Tuesday',
  'common:unit.weekDay2.Wednesday',
  'common:unit.weekDay2.Thursday',
  'common:unit.weekDay2.Friday',
  'common:unit.weekDay2.Saturday',
];
export const TRANSLATE_WEEKDAY_3_WORD_LIST = [
  'common:unit.weekDay3.Sunday',
  'common:unit.weekDay3.Monday',
  'common:unit.weekDay3.Tuesday',
  'common:unit.weekDay3.Wednesday',
  'common:unit.weekDay3.Thursday',
  'common:unit.weekDay3.Friday',
  'common:unit.weekDay3.Saturday',
];
export const WEEKDAY_NUM = [0, 1, 2, 3, 4, 5, 6];

export const getStartOfISOWeek = (day: Dayjs) => {
  if (day.utcOffset() === 0) {
    return day.utc().startOf('day').isoWeekday(1);
  }

  return day.startOf('day').isoWeekday(1);
};
export const getEndOfISOWeek = (day: Dayjs) => {
  if (day.utcOffset() === 0) {
    return day.utc().startOf('day').isoWeekday(7);
  }
  return day.startOf('day').isoWeekday(7);
};
