export enum StudyPurpose {
  MAIN_STUDY = 'main_study',
  DESCRIPTION = 'description',
}

export enum IndexName {
  ALL = '전체',
}

export enum WeekDay {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
}

export enum StudyReportType {
  DAILY_REPORT = 'DAILY_STUDY_RESULT',
  WEEKLY_REPORT = 'WEEKLY_STUDY_RESULT',
}

export enum CreditType {
  WEEKLY_ASSIGNMENT_PROGRESS_OVER_90 = 'WEEKLY_ASSIGNMENT_PROGRESS_OVER_90',
  DAILY_ELAPSED_TIME_OVER_3H = 'DAILY_ELAPSED_TIME_OVER_3H',
  EXCELLENT_STUDY_ATTITUDE = 'EXCELLENT_STUDY_ATTITUDE',
  PERFECT_ATTENDANCE_MONTH = 'PERFECT_ATTENDANCE_MONTH',
  HIGH_SCORE_ON_SCHOOL_EXAM = 'HIGH_SCORE_ON_SCHOOL_EXAM',
  RAPID_SCORE_IMPROVEMENT = 'RAPID_SCORE_IMPROVEMENT',
  DAILY_ASSIGNMENT_PROGRESS_UNDER_80 = 'DAILY_ASSIGNMENT_PROGRESS_UNDER_80',
  LATE_OVER_30M = 'LATE_OVER_30M',
  ABSENCE_NOTICE_TODAY = 'ABSENCE_NOTICE_TODAY',
  UNEXCUSED_ABSENCE = 'UNEXCUSED_ABSENCE',
  NO_RESPONSE_OVER_20M = 'NO_RESPONSE_OVER_20M',
  NON_STUDY_ACTIVITY = 'NON_STUDY_ACTIVITY',
  INSINCERE_STUDY_ATTITUDE = 'INSINCERE_STUDY_ATTITUDE',
  INAPPROPRIATE_BEHAVIOR_OR_LANGUAGE = 'INAPPROPRIATE_BEHAVIOR_OR_LANGUAGE',
  ETC = 'ETC',
}
