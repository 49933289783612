import { css } from 'styled-components';

export const basicScrollBoxStyle = css`
  &::-webkit-scrollbar {
    display: block;
    width: 0.875rem;
    height: 100%;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--gray-70);
    width: 0.375rem;
    background-clip: padding-box;
    border: 0.25rem solid transparent;
    border-radius: 4rem;
  }
`;
