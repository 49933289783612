import { getMyClassFiltering } from '@asset/function/queryStringFunctions';
import { CommonListFilteringKey } from '@components/common/list/CommonList';
import { MY_CLASS_PATH_QUERY } from 'pages/manage/my-class/[...myClassType]';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface FilteringObjQuery {
  urlQueryObj: object;
  setUrlQueryObj: (obj) => void;
}

interface LastStatusQuery {
  studentName?: string;
  teacherName?: string;
  materialName?: string;
  solvedAt?: string;
  studyStatusType?: string;
}
interface TotalStatusQuery {
  studentName?: string;
  teacherName?: string;
  materialName?: string;
  studyStatusType?: string;
  solvingResult?: string;
  isCheckAnswer?: string;
  solutionCheck?: string;
  solvedAt?: string;
}

interface StatusFilteringObjQuery {
  lastUrlQueryObj: LastStatusQuery;
  setLastUrlQueryObj: (obj) => void;
  totalUrlQueryObj: TotalStatusQuery;
  setTotalUrlQueryObj: (obj) => void;
}

interface AttendanceFilteringObjQuery {
  todayUrlQueryObj: object;
  setTodayUrlQueryObj: (obj) => void;
  totalUrlQueryObj: object;
  setTotalUrlQueryObj: (obj) => void;
}

export const useStatusFilteringQueryStore = create<StatusFilteringObjQuery>(
  (set) => ({
    lastUrlQueryObj: null,
    setLastUrlQueryObj: (obj: LastStatusQuery) => set({ lastUrlQueryObj: obj }),
    totalUrlQueryObj: null,
    setTotalUrlQueryObj: (obj: TotalStatusQuery) =>
      set({ totalUrlQueryObj: obj }),
  }),
);

export const useAttendanceFilteringQueryStore =
  create<AttendanceFilteringObjQuery>((set) => ({
    todayUrlQueryObj: null,
    setTodayUrlQueryObj: (obj: object) => set({ todayUrlQueryObj: obj }),
    totalUrlQueryObj: null,
    setTotalUrlQueryObj: (obj: object) => set({ totalUrlQueryObj: obj }),
  }));

export const useManageQuestionFilteringQueryStore = create<FilteringObjQuery>(
  (set) => ({
    urlQueryObj: null,
    setUrlQueryObj: (obj: object) => set({ urlQueryObj: obj }),
  }),
);

export const useStudentListFilteringQueryStore = create<FilteringObjQuery>(
  (set) => ({
    urlQueryObj: null,
    setUrlQueryObj: (obj: object) => set({ urlQueryObj: obj }),
  }),
);

interface MyClassFilteringObjQuery {
  urlQueryObj: object;
  setUrlQueryObj: (obj: object) => void;
  resetUrlQueryObj: () => void;
}

export const useMyClassFilteringQueryStore = create(
  persist<MyClassFilteringObjQuery>(
    (set) => ({
      urlQueryObj: null,
      setUrlQueryObj: (queryObj: object) => {
        const notStore = [MY_CLASS_PATH_QUERY];
        const _urlQueryObj = getMyClassFiltering(queryObj, notStore);
        set({ urlQueryObj: _urlQueryObj });
      },
      resetUrlQueryObj: () => {
        set({ urlQueryObj: null });
      },
    }),
    {
      name: 'table-list-my-class-storage',
      partialize: (state) => {
        const onlyStoreInMemory: string[] = [
          CommonListFilteringKey.SEARCH_VALUE,
          CommonListFilteringKey.PAGE_NUMBER,
        ];
        if (state.urlQueryObj) {
          const newState = Object.fromEntries(
            Object.entries(state.urlQueryObj).filter(
              ([key]) => !onlyStoreInMemory.includes(key),
            ),
          );
          state.urlQueryObj = newState;
        }
        return state;
      },
    },
  ),
);
export const useMyClassWeeklyGoalFilteringQueryStore = create(
  persist<MyClassFilteringObjQuery>(
    (set) => ({
      urlQueryObj: null,
      setUrlQueryObj: (queryObj: object) => {
        const notStore = [MY_CLASS_PATH_QUERY];
        const _urlQueryObj = getMyClassFiltering(queryObj, notStore);
        set({ urlQueryObj: _urlQueryObj });
      },
      resetUrlQueryObj: () => {
        set({ urlQueryObj: null });
      },
    }),
    {
      name: 'table-list-my-class-weekly-goal-storage',
      partialize: (state) => {
        const onlyStoreInMemory: string[] = [
          CommonListFilteringKey.START_DATETIME,
          CommonListFilteringKey.END_DATETIME,
          CommonListFilteringKey.SEARCH_VALUE,
          CommonListFilteringKey.PAGE_NUMBER,
        ];
        if (state.urlQueryObj) {
          const newState = Object.fromEntries(
            Object.entries(state.urlQueryObj).filter(
              ([key]) => !onlyStoreInMemory.includes(key),
            ),
          );
          state.urlQueryObj = newState;
        }
        return state;
      },
    },
  ),
);
