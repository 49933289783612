import axios from 'axios';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/nextjs';

const authAPI = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_ENV_HOST}`,
});
authAPI.defaults.withCredentials = true;

authAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { method, url, params, data: requestData, headers } = error.config; // axios의 error객체
    const { data: responseData, status } = error.response; // axios의 error객체

    if (Math.floor(error.response.status / 100) === 4) {
      if (error.response.status === 401) {
        if (
          error.config.url !== '/api/users/token/refresh/' &&
          error.config.url !== '/api/users/token/blacklist/' &&
          (error.response.data.message === 'ACCESS_TOKEN_EXPIRED' ||
            error.response.data.message === 'ACCESS_TOKEN_NOT_FOUND')
        ) {
          const originalRequest = error.config;
          originalRequest._retry = true;
          const { data } = await authAPI.post('/api/users/token/refresh/');
          const { access } = data;

          originalRequest.headers['Authorization'] = `Bearer ${access}`;
          authAPI.defaults.headers.common['Authorization'] = `Bearer ${access}`;

          return authAPI(originalRequest);
        } else if (error.response.data.code === 'LOGIN_FAILED') {
          toast.error('이메일 혹은 비밀번호를 확인해주세요.');
          return Promise.reject(error);
        } else {
          window.location.replace(`/account/login`);
          localStorage.removeItem('persistAtom');
          return Promise.reject(error);
        }
      }
      if (error.response.status === 403) {
        window.location.replace('/account/login');
        return Promise.reject(error);
      }

      if (error.response.status === 409) {
        return Promise.reject(error);
      }
    }

    if (Math.floor(error.response.status / 100) === 5) {
      toast.error('요청을 처리할 수 없습니다. 관리자에게 문의하세요.');
    }

    Sentry.setContext('API Request Detail', {
      method,
      url,
      params,
      requestData,
      headers,
    });
    Sentry.setContext('API Response Detail', {
      status,
      responseData,
    });

    return Promise.reject(error);
  },
);

export { authAPI };
