import {
  encodeQueryValue,
  getUrlByQueryParameterObj,
} from '@asset/function/queryStringFunctions';
import { authAPI } from '../core/instance';
import { toast } from 'react-toastify';
import {
  StudyContentPageType,
  StudyContentType,
} from '@asset/enum/materialEnum';
import { LayOutType } from '@asset/type/common/type';
import {
  AssignmentStudyPurpose,
  SolvingResult,
  StudyItemType,
  StudyStep,
} from '@asset/enum/problemSolvingEnum';
import { AssignmentType } from '@asset/enum/studyContentEnum';
import { StudyItem } from '@asset/type/common/type';
import { CommonResponse } from '@apis/common/response';
import { WrongPageExtractionType } from '@components/common/modal/wrong-problem-material/WrongPageExtractionTypeForm';
import { Mastery } from '@asset/type/mastery';

export interface ContentValue {
  problemStatusId: number;
  pageId: number;
  problemIndex: number;
  index: string;
  subIndex: string;
  // TODO:  type 이름 변경
  studyItemType: StudyItemType;
  problemStatusDetails: {
    id: number;
    studyStatusType: StudyStep;
    solvingResult: string;
    elapsedTime: number;
    studiedAt: number;
    hasErrorReport: boolean;
    isErrorReportResolved: boolean;
    hasNotUnderstandReport: boolean;
    isBookmark: boolean;
    hasWarning: boolean;
    isDescriptionMission?: boolean;
  }[];
  studyHistory?: StudyHistory;
}
export interface ProblemListResponse {
  success: boolean;
  pagination: {
    hasMore: boolean;
    nextOffset: number | null;
    previousOffset: number | null;
  };
  progressInfo: {
    totalProblemStatusCount: number;
    totalCompletedCount: number;
    progressRate: number;
    correctRate: number;
  };
  contentValues: ContentValue[];
  // TODO: contenttype, studyType 구분 해서 타입 지정하기
  contentType: string;
  studyType: string;
  timeLimit: number;
}

export const getProblemList = async (
  pageType,
  studyContentId,
  pickedClassificationId,
  pickedClassificationType,
  problemFiltering,
  limit = 100,
  offset = 0,
): Promise<ProblemListResponse> => {
  let apiUrl = `/api/teachers/study-content-detail/${studyContentId}/?limit=${limit}&offset=${offset}`;

  if (problemFiltering) {
    problemFiltering
      .split(',')
      .map((filtering) => (apiUrl += `&${filtering}=${true}`));
  }
  if (pageType) apiUrl += `&pageType=${pageType}`;

  if (pickedClassificationId && pickedClassificationType) {
    apiUrl += `&classificationType=${pickedClassificationType}&classificationId=${pickedClassificationId}`;
  }

  const data = await authAPI
    .get<ProblemListResponse>(apiUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export interface StudyHistory {
  pageId: number;
  problemStatusDetails: {
    id: number;
    pageId: number;
    studyStep: StudyStep;
    solvingResult: SolvingResult;
    studentCompletedAt: string;
    myContent: {
      id: number;
      createdAt: string;
      subTitle: string;
    };
    assignments: [
      {
        id: number;
        createdAt: string;
        subTitle: string;
      },
    ];
  }[];
}

export const getStudyHistories = async (
  pageIds: number[],
  studentId: number,
): Promise<{ success: boolean; histories: StudyHistory[] }> => {
  const pageIdsQuery = encodeQueryValue(pageIds);
  const apiUrl = `/api/study-data/study-histories/?pageIds=${pageIdsQuery}&studentId=${studentId}`;

  const data = await authAPI
    .get(apiUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getClassificationAndLastProblem = async (
  pageType,
  studyContentId,
  studentId,
  pageId,
) => {
  let apiUrl = `/api/study-data/tables/?pageType=${pageType}`;
  if (studyContentId) apiUrl += `&studyContentId=${studyContentId}`;
  if (studentId) apiUrl += `&studentId=${studentId}`;
  if (pageId) apiUrl += `&pageId=${pageId}`;

  const data = await authAPI
    .get(apiUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getMaterialClassification = async (studyMaterialId) => {
  const apiUrl = `/api/materials/${studyMaterialId}/classification/`;

  const data = await authAPI
    .get(apiUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getAssignmentPreview = async (studentId, requestData) => {
  const apiUrl = `/api/students/${studentId}/my-assignments/`;

  const data = await authAPI
    .post(apiUrl, requestData)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const createCustomMission = async (requestData) => {
  const apiUrl = `/api/teachers/study-content/customize/`;

  const data = await authAPI
    .patch(apiUrl, requestData)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch((error) => {
      if (error.response) toast.error(error.response.data.message);
      return error.response.data;
    });
  return data;
};

export const updateCustomMissionApi = async (
  requestData,
  editingAssignmentId,
) => {
  const apiUrl = `/api/study-data/assignments/edit/?assignmentId=${editingAssignmentId}`;

  const data = await authAPI
    .patch(apiUrl, requestData)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch((error) => {
      if (error.response) toast.error(error.response.data.message);
      return error.response.data;
    });
  return data;
};

export const getAssignmentClassificationPreview = async (
  myStudyContentId,
  classificationId,
  classificationType,
) => {
  let apiUrl = `/api/students/my-assignments/preview/classification/?myStudyContentId=${myStudyContentId}`;
  if (classificationId) {
    apiUrl += `&classificationId=${classificationId}`;
  }
  if (classificationType) {
    apiUrl += `&classificationType=${classificationType}`;
  }

  const data = await authAPI
    .get(apiUrl)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const submitRestudyWrongProblemModal = async (requestData) => {
  const data = await authAPI
    .post(`/api/materials/restudy-wrong-problem/`, requestData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const submitProgressModal = async (
  pageType,
  studyContentId,
  pagedIds,
) => {
  const apiUrl = `/api/teachers/study-content-detail/check-progress/?studyContentId=${studyContentId}&pageType=${pageType}`;

  const data = await authAPI
    .patch(apiUrl, { pageIds: pagedIds })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const scoringApi = async (
  result,
  detailId,
  studyContentId,
  pageType,
) => {
  const apiUrl = `/api/teachers/problemstatusdetail/${detailId}/`;

  const data = await authAPI
    .patch(apiUrl, {
      solvingResult: result,
      studyContentId: studyContentId,
      studyContentType: pageType,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export interface ModalStudyItemDataResponse {
  success: boolean;
  studyItemDetail: object;
  pagination?: {
    currPage: number;
    totalPage: number;
    nextPage: number | null;
    previousPage: number | null;
  };
  surroundPage: {
    previousPageId: number;
    previousStudyItemType: string;
    nextPageId: number;
    nextStudyItemType: string;
  };
  studyItem?: StudyItem;
  layoutType?: LayOutType;
}

export const getModalStudyItemDataApi = async (
  studyContentId,
  pageType,
  pageId,
  problemStatusDetailId,
  reqPage,
  showAnswer,
  studyStatusType,
): Promise<ModalStudyItemDataResponse> => {
  let apiUrl = `/api/teachers/study-content-detail/${studyContentId}/detail/?pageType=${pageType}&pageId=${pageId}&showAnswer=${showAnswer}`;
  if (problemStatusDetailId)
    apiUrl += `&problemStatusDetailId=${problemStatusDetailId}`;
  if (reqPage) apiUrl += `&reqPage=${reqPage}`;
  if (studyStatusType) apiUrl += `&studyStatusType=${studyStatusType}`;

  const data = await authAPI
    .get<ModalStudyItemDataResponse>(apiUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getProblemStatusDetailModalDatasApi = async (
  problemStatusDetailId,
) => {
  const apiUrl = `/api/teachers/study-content-detail/modal/report/?problemStatusDetailId=${problemStatusDetailId}`;

  const data = await authAPI
    .get(apiUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getStudyContentReportData = async (
  analysisType,
  studyContentList,
  course,
  studentId,
) => {
  let url = `/api/teachers/study-content/report/?analysisType=${analysisType}`;
  if (studyContentList) url += `&studyContentList=${studyContentList}`;
  if (course) url += `&course=${course}`;
  if (studentId) url += `&studentId=${studentId}`;

  const data = await authAPI
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const getStudyContentReportToggle = async (
  studyContentId,
  isAssignment,
  classificationId = undefined,
  classificationName = undefined,
  classificationType = undefined,
) => {
  let url = `/api/teachers/study-content/report/toggle/?studyContentList=${studyContentId}&isAssignment=${isAssignment}`;
  if (classificationId) url += `&classificationId=${classificationId}`;
  if (classificationName) {
    url += `&classificationName=${classificationName}`;
  }
  if (classificationType) {
    url += `&classificationType=${classificationType}`;
  }
  const data = await authAPI
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const reStudyApi = async (
  detailId,
  studyContentId,
  studyContentType,
) => {
  const apiUrl = `/api/teachers/problemstatusdetail/${detailId}/?studyContentId=${studyContentId}&studyContentType=${studyContentType}`;

  const data = await authAPI
    .delete(apiUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const submitFeedbackApi = async (studyStatusType, reqData) => {
  const data = await authAPI
    .patch(`/api/study-data/feedback/?type=${studyStatusType}`, reqData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const checkSolvingApi = async (problemStatusDetailId, reqData) => {
  const data = await authAPI
    .patch(
      `/api/teachers/problemstatusdetail/solution-check/${problemStatusDetailId}/`,
      reqData,
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const patchHideContents = async (reqData) => {
  const data = await authAPI
    .patch(`/api/study-data/hide-contents/`, reqData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const submitDescriptionAssignmentApi = async (requestData) => {
  const data = await authAPI
    .post('/api/teachers/study-content/description-assignment/', requestData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const getDescriptionAssignmentProblemCountApi = async (
  queryObj,
): Promise<{
  success: boolean;
  pageCount: number;
}> => {
  let api = '/api/teachers/study-content/description-assignment/';
  const queryString = getUrlByQueryParameterObj('', queryObj);
  api += `${queryString}`;
  const data = await authAPI
    .get(api)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getMaterialIdByCourseApi = async (course) => {
  const data = await authAPI
    .get(`/api/materials/material-level/?course=${course}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getPredictTimeAndProblemCountApi = async (reqData) => {
  const data = await authAPI
    .post('/api/study-data/supplement/preview/predict/', reqData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getStudyContentProgressInfoApi = async (
  pageType: StudyContentPageType,
  studyContentId: number,
  queryString: {
    classificationId: number;
    classificationType: string;
  } | null,
): Promise<StudyContentProgressInfoResponse> => {
  let api = `/api/study-data/${pageType}/${studyContentId}/progress/`;
  if (queryString) api += getUrlByQueryParameterObj('', queryString);
  const data = await authAPI
    .get<StudyContentProgressInfoResponse>(api)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export interface StudyContentProgressInfoResponse extends CommonResponse {
  waitProblemCount: number;
  correctProblemCount: number;
  gradedProblemCount: number;
  completedProblemCount: number;
  totalProblemCount: number;
  progressRate: number;
  correctRate: number;
}

export const getStudyMaterialStatusTable = async (
  studyMaterialId,
  studentId,
  limit = 10,
  offset = 0,
  classificationQueryObj = null,
) => {
  let api = `/api/study-data/study-material-status/table/`;

  api += getUrlByQueryParameterObj(
    `?studyMaterialId=${studyMaterialId}&studentId=${studentId}&limit=${limit}&offset=${offset}`,
    classificationQueryObj,
  );
  const data = await authAPI
    .get(api)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const getStudyMaterialStatusClassificationTree = async (
  studyMaterialId,
  studentId,
) => {
  const api = `/api/study-data/study-material-status/classification-rate-tree/?studyMaterialId=${studyMaterialId}&studentId=${studentId}`;

  const data = await authAPI
    .get(api)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export interface CreateWrongProblemMaterialRequestData {
  studyContentId: number;
  studyContentType: string;
  pageIds?: number[];
  subTitle: string;
  timeLimit: number | null;
  includeWrongProblem: boolean;
  similarProblemCount: number;
  wrongPageExtractionType: WrongPageExtractionType;
}

export interface CreateRestudyMaterialIncludeMultipleStudyContentRequestData {
  targets: (
    | {
        assignmentId: number;
      }
    | {
        myContentId: number;
        pageIds: number[];
      }
  )[];
  requestContentType:
    | StudyContentType.MY_TEST
    | StudyContentType.MY_STUDY_MATERIAL;
  subTitle: string;
  includeWrongProblem: boolean;
  similarProblemCount: number;
  wrongPageExtractionType: WrongPageExtractionType;
  timeLimit?: number;
  masteryLevels?: Mastery[];
}

export const createWrongProblemMaterialIncludeMultipleStudyContentAndMission =
  async (
    startDateTime: number,
    endDateTime: number,
    requestData: CreateRestudyMaterialIncludeMultipleStudyContentRequestData,
    cutOffScore: number,
  ) => {
    await authAPI
      .post(`/api/materials/restudies/`, requestData)
      .then(async (response) => {
        const wrongProblemMaterial = response.data;
        const missionResponse = await createCustomMission({
          studyContentType: StudyContentType.MY_ASSIGNMENT,
          studyContentId: wrongProblemMaterial.myContentId,
          checkedPageIdList: wrongProblemMaterial.pageIds,
          subTitle: requestData.subTitle,
          studyPurpose: [AssignmentStudyPurpose.MAIN_STUDY],
          startDate: startDateTime,
          dueDate: endDateTime,
          timeLimit: requestData.timeLimit,
          cutOffScore: cutOffScore,
          assignmentType:
            requestData.requestContentType === StudyContentType.MY_TEST
              ? AssignmentType.NORMAL_TEST
              : AssignmentType.NORMAL,
        });
        if (missionResponse.success) {
          if (missionResponse.overlappedProblemCount) {
            toast.success(
              `중복된 ${missionResponse.overlappedProblemCount}문제를 자동으로 제외했어요.`,
            );
          }
          toast.success(
            `오답 ${
              requestData.requestContentType === StudyContentType.MY_TEST
                ? '테스트'
                : '복습자료'
            } 미션이 부여되었습니다.`,
          );
        }
      })
      .catch((error) => {
        if (error.response) toast.error(error.response.data?.message);
      });
  };
